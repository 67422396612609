import React, { useEffect, useState } from 'react';
import { Calendar30DaysIcon, CancelOutlinedIcon, CarrotDownIcon, CarrotUpIcon, CloseIcon, JourneyIcon, StoppedIcon } from 'components/mui';
import { Dialog, DialogContent, DialogTitle, Stack, Tooltip, Box, Typography, IconButton, DialogActions, Divider, Button, Drawer, Checkbox } from '@mui/material';
import { Scoped, k } from 'kremling';
const republishOptions = [{
  key: 'close',
  title: 'Close it',
  description: 'No new customers will enter the version of this journey being replaced, customers currently in the journey will continue',
  icon: <CancelOutlinedIcon size="lg" />
}, {
  key: 'stop',
  title: 'Stop it',
  description: 'All customers in the journey version being replaced will stop immediately and no new customers will enter it',
  icon: <StoppedIcon size="lg" />
}];
export const RepublishCheck = props => {
  const [expanded, setExpanded] = useState(true);
  return <>
      <Stack sx={{
      px: '10px'
    }} spacing={2}>
        <Box onClick={e => {
        e.stopPropagation();
        if (!expanded) {
          setExpanded(!expanded);
        }
      }} sx={{
        px: '10px',
        border: '2px solid #E2E2E2',
        borderRadius: '14px',
        cursor: expanded ? 'auto' : 'pointer',
        '&:hover': {
          boxShadow: expanded ? 'none' : '0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
        }
      }}>
          <Stack sx={{
          p: 1
        }} spacing={1}>
            <Stack spacing={1}>
              <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '600',
                  fontSize: '16px'
                }}> Previous Revision</Typography>
                </Box>

                {/*     <Box sx={{ height: '40px', width: '40px' }}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpanded(!expanded);
                    }}
                  >
                    {expanded ? <CarrotUpIcon /> : <CarrotDownIcon />}
                  </IconButton>
                 </Box> */}
              </Box>

              {expanded && <Typography>What would you like to do with your previously published version of this journey?</Typography>}
            </Stack>

            {expanded && <Scoped css={css}>
                <Stack spacing={2}>
                  {republishOptions.map(option => <Stack key={option.title} onClick={() => {
                props.setDataToSubmit({
                  ...props.dataToSubmit,
                  republish: option.key
                });
              }} spacing={2} direction="row" sx={{
                cursor: 'pointer',
                px: '10px',
                border: props.dataToSubmit.republish == option.key ? '2px solid #3898D9' : '1px solid #E2E2E2',
                borderRadius: '14px',
                '&:hover': {
                  boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
                }
              }}>
                      <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  py: '10px',
                  pl: '10px'
                }}>
                        {option.icon}
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%'
                }}>
                        <Stack spacing={1} sx={{
                    p: 1
                  }}>
                          <Typography sx={{
                      fontWeight: '600'
                    }}>{option.title} </Typography>
                          <Typography>{option.description}</Typography>
                        </Stack>
                        <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}>
                          <Box />
                        </Box>
                      </Box>
                    </Stack>)}
                </Stack>
              </Scoped>}
          </Stack>
        </Box>
      </Stack>
    </>;
};
const css = {
  styles: `[kremling="i42"] body,body[kremling="i42"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i42"] .node-selection,[kremling="i42"].node-selection {
  text-align: center;
  vertical-align: top;
  flex-basis: 33.333333%;
  justify-items: center;
  display: flex;
}

[kremling="i42"] .node-selection__img,[kremling="i42"].node-selection__img {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
}

[kremling="i42"] .node-selection__img img,[kremling="i42"].node-selection__img img {
  width: 30px;
  height: 30px;
  display: inline-block;
  opacity: 0.7;
}

[kremling="i42"] .node-selection__name,[kremling="i42"].node-selection__name {
  color: #1D252D;
  font-size: 12px;
  font-family: Nexa;
}`,
  id: 'i42',
  namespace: 'kremling'
};