import { Box, Card, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import { ArrowCooldownIcon, CloseIcon, StatusDotIcon } from 'components/mui';
import moment from 'moment';
import React from 'react';
export function ImportDetails({
  open,
  close,
  importDetails
}) {
  return <>
      <Card sx={{
      padding: 2,
      margin: 2,
      borderRadius: '14px',
      overflowY: 'auto'
    }} elevation={3}>
        <Box sx={{
        margin: '8px'
      }}>
          <Stack direction={'row'} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
            <Box sx={{
            py: '24px'
          }}>
              <h1>Import Details:</h1>
            </Box>
            <Box>
              <IconButton onClick={() => {
              close();
            }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
          <Stack direction="row" spacing={3} sx={{
          display: 'flex',
          alignItems: 'flex-start'
        }}>
            <Stack direction={'column'} id="import-details-row-labels" sx={{
            display: 'flex',
            alignItems: 'flex-start',
            pl: '40px'
          }} spacing={3}>
              <Stack direction={'row'} spacing={2}>
                <Typography fontWeight={'bold'}>Import File Name:</Typography>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Typography fontWeight={'bold'}>Import File Type:</Typography>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Typography fontWeight={'bold'}>Import created on:</Typography>
              </Stack>
              <Stack direction={'row'} spacing={2}>
                <Typography fontWeight={'bold'}>Created by:</Typography>
              </Stack>
              <Stack direction={'row'} sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Box>
                  <Typography fontWeight={'bold'} sx={{
                  pr: '16px'
                }}>
                    Import Status:
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack direction={'column'} id="import-details-row-data" sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'left',
            pl: '40px'
          }} spacing={3}>
              <Typography>{`${importDetails.filename}`}</Typography>
              <Typography>{importDetails.type}</Typography>
              <Typography>{moment(importDetails.created_when).format('MMMM Do YYYY')}</Typography>
              <Typography>{importDetails.created_by_first_name + ' ' + importDetails.created_by_last_name}</Typography>

              <Stack direction={'row'} spacing={1} sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <StatusDotIcon fill={importDetails.status === 'success' ? '#66CC99' : importDetails.status === 'paused' || importDetails.status === 'stopped' ? '#F3D250' : '#F30F0F'} />
                </Box>
                <Box>
                  <Typography>
                    {importDetails.status === 'success' ? 'Successful' : importDetails.status === 'paused' ? 'Paused' : importDetails.status === 'stopped' ? 'Stopped' : 'Error'}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{
          mt: '24px',
          mb: '24px'
        }} />
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          py: '24px'
        }}>
            <h1>Import Mapping:</h1>
          </Box>
          <Stack direction="row" spacing={3} sx={{
          display: 'flex',
          alignItems: 'flex-start'
        }}>
            <Stack id="import-mapping-row-labels" direction="column" sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'left',
            pl: '30px'
          }} spacing={3}>
              <Box>
                <Typography fontWeight="bold">Total Records:</Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold">Records Imported:</Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold">Records Updated:</Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold">Incompatable Records:</Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold">Unique ID:</Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold">Import Issues:</Typography>
              </Box>
              {importDetails?.options?.import_issues && <Box>
                  <Typography fontWeight="bold">Issue Description:</Typography>
                </Box>}
            </Stack>
            <Stack direction={'column'} id="import-mapping-row-data" sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'left',
            pl: '30px'
          }} spacing={3}>
              <Typography>{importDetails.total_records}</Typography>
              <Typography>{importDetails.records_inserted}</Typography>
              <Typography>{importDetails.records_updated}</Typography>
              <Typography>{importDetails.records_skipped}</Typography>
              <Typography>{importDetails?.options?.unique_id ? importDetails?.options?.unique_id : 'None'}</Typography>
              <Typography>{importDetails?.options?.import_issues.length > 0 ? 'Yes' : 'No'}</Typography>
              {importDetails?.options?.import_issues && importDetails.options.import_issues.length > 0 ? importDetails.options.import_issues.map((issue, index) => <Stack key={index} direction="row" spacing={1} alignItems="center">
                    <Box>
                      <Typography>{`${issue.issue}`}</Typography>
                    </Box>
                  </Stack>) : <Typography>No import issues found.</Typography>}
            </Stack>
          </Stack>
          <Divider sx={{
          mt: '24px',
          mb: '24px'
        }} />
          <Stack direction="row" id="import-mapping-row-data" spacing={3} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pl: '24px'
        }}>
            {importDetails?.options?.maps && <Stack direction="column" sx={{
            display: 'flex',
            alignItems: 'center'
          }} spacing={2}>
                {Object.entries(importDetails.options.maps).map(([key, value]) => <Stack key={key} direction="row" spacing={1} alignItems="center">
                    <Box>
                      <TextField sx={{
                  width: '345px',
                  backgroundColor: '#f9f9f9',
                  borderRadius: '14px',
                  '& .Mui-disabled': {
                    color: 'black',
                    WebkitTextFillColor: 'black'
                  }
                }} variant="outlined" autoComplete="off" fullWidth value={`Source Field: ${key}`} disabled />
                    </Box>
                    <ArrowCooldownIcon />
                    <Box>
                      <TextField sx={{
                  width: '345px',
                  backgroundColor: '#f9f9f9',
                  borderRadius: '14px',
                  '& .Mui-disabled': {
                    color: 'black',
                    WebkitTextFillColor: 'black'
                  }
                }} variant="outlined" autoComplete="off" fullWidth value={`Cinch Data Field: ${value}`} disabled />
                    </Box>
                  </Stack>)}
              </Stack>}
          </Stack>
        </Box>
      </Card>
    </>;
}