import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { getSegmentCustomers, getSegmentExportCustomers } from '../../shared/common.api';
import { Box, Button, IconButton, Typography, Modal, Divider, Stack, Table, TableBody, TableCell, TableRow, Snackbar, Grid } from '@mui/material';
import { maxHeight } from '@mui/system';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ArrowCooldownIcon, Calendar30DaysIcon, CloseIcon, CustomerIcon, DragIndicatorIcon, HelpIcon, ShoppingCartIcon, TransactionIcon, UpwardArrowIcon, VehicleIcon, snackbarService } from 'components/mui';
import { ScheduleOutlined } from '@mui/icons-material';

/**
 ExportSegment:
  This CUSTOM component is a modal that allows the user to export a segment. The user can select which fields to export and then download the segment as a CSV file.
  The component uses the UserStateContext to get the company fields and the user's permissions.

  @param {Object} props - The props passed in which include: segmentData, tempFields, isExporting, and handleClose.
  @param {prop} segmentData: The segment data object.
  @param {prop} isExporting: A boolean that indicates if the user is exporting the segment.
  @param {prop} handleClose: A function that closes the modal.

  @param {object} segmentData: The segment data object that contains specific segment data information
  @param {object} segmentData.included: The included field of the segment data object,
  options are: 'possession', 'schedule', 'transaction', 'cart', and NULL which is the default for customer type.

  @param {array} availableFields: The available fields that the user can select to export.
  @param {array} selectedFields: The selected fields that the user has chosen to export.

  // functions
  @param {function} makeOverflowTooltip: A function that creates a tooltip for overflowing text.
  @param {function} handleExport: A function that handles the export of the segment data by fetching the segment customers with getSegmentCustomers() and creating a CSV file to download.


  */

export const ExportSegment = props => {
  const {
    companyFields
  } = useContext(UserStateContext);
  const [segmentData] = useState(props.segmentData);
  const [loading, setLoading] = useState(false);
  const [availableFields, setAvailableFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [sortAvailableFields, setSortAvailableFields] = useState(0);
  const [sortSelectedFields, setSortSelectedFields] = useState(0);
  const [largeFileDetected, setLargeFileDetected] = useState(props.largeFileDetected);
  const makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  useEffect(() => {
    // fetch customer fields
    const localCustomerFields = companyFields.find(field => field?.id === 'customer').fields.map(field => {
      field.model = 'customer';
      return field;
    });
    let segmentTypeFields = [];

    // get the segment type fields based on the segmentData.included
    if (segmentData?.included) {
      if (segmentData?.included === 'possession') {
        // possession is vehicle
        segmentTypeFields = companyFields.find(field => field?.id === 'vehicle');
      } else if (segmentData?.included === 'schedule') {
        segmentTypeFields = companyFields.find(field => field?.id === 'schedule');
      } else if (segmentData?.included === 'transaction') {
        segmentTypeFields = companyFields.find(field => field?.id === 'transaction');
      } else if (segmentData?.included === 'cart') {
        segmentTypeFields = companyFields.find(field => field?.id === 'cart');
      }
      segmentTypeFields = segmentTypeFields.fields.map(field => {
        field.model = segmentData.included;
        return field;
      });
      setAvailableFields([...localCustomerFields, ...segmentTypeFields]);
    } else {
      setAvailableFields(localCustomerFields);
    }
    if (props.isExporting && !exportModalOpen) {
      setExportModalOpen(true);
    }
  }, []);

  /**
  @param {function} handleExport: handles exporting segment data by fetching with getSegmentCustomers() and creating a CSV file to download
  */
  const handleExport = () => {
    if (largeFileDetected) {
      finallyExport(largeFileDetected);
    } else {
      finallyExport();
    }
    setLoading(true);
  };
  const finallyExport = largeFileDetected => {
    getSegmentExportCustomers(segmentData.id, selectedFields).then(response => {
      if (response?.data?.results === 'failed') {
        snackbarService.popup({
          message: 'Error exporting segment. Please try again.',
          type: 'error',
          action: <React.Fragment>
                <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
        });
        setLoading(false);
        return;
      } else if (response?.data?.results === 'success' && largeFileDetected) {
        snackbarService.popup({
          message: `Exporting ${segmentData?.name} to CSV. ${'We will email you a link to the File Manager page where your CSV file will be when it is ready for download. This may take a few minutes.'}`,
          type: 'info',
          action: <React.Fragment>
                <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
        });
        return;
      } else {
        const csvRows = [];
        const headers = selectedFields.map(field => field.name);
        csvRows.push(headers.join(','));
        const rows = response?.data?.file_contents?.split('\r\n');

        // Skip the first row (headers) and process the rest
        rows.slice(1).forEach(rowString => {
          // Split the row string into an array of values
          const rowValues = rowString.split(',');
          const values = selectedFields.map((field, index) => {
            return rowValues[index];
          });
          csvRows.push(values.join(','));
        });
        const csvData = csvRows.join('\n');
        const blob = new Blob([csvData], {
          type: 'text/csv'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('download', `${segmentData?.name}.csv`);
        a.setAttribute('href', url);
        a.click();
        setLoading(false);
        snackbarService.popup({
          message: `Exported ${segmentData?.name} to CSV. ${'Please navigate to your browser downloads folder to see your file.'}`,
          type: 'info',
          action: <React.Fragment>
                <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
        });
      }
    }).catch(error => {
      // fallback error if anything fails
      setLoading(false);
      setLargeFileDetected(false);
      snackbarService.popup({
        message: 'Error downloading file. Please try again.',
        type: 'error',
        action: <React.Fragment>
              <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
      });
    });
  };
  return <>
      {loading ? <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '200px',
      width: '400px',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: '24px',
      borderRadius: '28px',
      borderBottom: '1px solid #E2E2E2',
      zIndex: '1000'
    }}>
          <Typography>Loading...</Typography>
        </Box> : <>
          <Modal id="export-segment-modal" open={exportModalOpen} onClose={() => {
        props.handleClose();
        setExportModalOpen(false);
      }}>
            <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: '655px',
          width: '660px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: '24px',
          borderRadius: '28px',
          borderBottom: '1px solid #E2E2E2'
        }}>
              <Box sx={{
            p: '0px 8px 20px 14px'
          }}>
                <Typography sx={{
              fontSize: '24px',
              fontWeight: '400',
              fontStyle: 'normal'
            }}>
                  Export Segment
                </Typography>
              </Box>

              <Box sx={{
            p: 0,
            marginLeft: '-24px',
            marginRight: '-24px'
          }}>
                <Divider />
              </Box>

              {loading || !availableFields ? <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
                  <Typography>Loading...</Typography>
                </Box> : <>
                  <Stack id="header-container" direction="row" sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              pt: '15px'
            }}>
                    <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pl: '33px',
                borderTop: '1px solid #E2E2E2',
                borderLeft: '1px solid #E2E2E2',
                borderRight: '1px solid #E2E2E2',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                borderBottom: '1px solid #fff',
                height: '40px',
                width: '255px'
              }}>
                      <Typography sx={{
                  fontSize: '20px',
                  fontWeight: '400',
                  fontStyle: 'normal'
                }}>
                        Available Fields
                      </Typography>
                      <Tooltip title="Sort by name">
                        <IconButton onClick={() => {
                    const sortedFields = [...availableFields].sort((a, b) => {
                      return sortAvailableFields ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                    });
                    setAvailableFields(sortedFields);
                    // Toggle the sorting order for the next click
                    setSortAvailableFields(!sortAvailableFields);
                  }}>
                          <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      // flip vertically if sorting in descending order
                      transform: `scaleY(${sortAvailableFields ? -1 : 1})`,
                      height: '14px',
                      width: '14px'
                    }}>
                            <UpwardArrowIcon />
                          </Box>
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pl: '15px',
                borderTop: '1px solid #E2E2E2',
                borderLeft: '1px solid #E2E2E2',
                borderRight: '1px solid #E2E2E2',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                borderBottom: '1px solid #fff',
                height: '40px',
                width: '255px'
              }}>
                      <Typography sx={{
                  fontSize: '20px',
                  fontWeight: '400',
                  fontStyle: 'normal'
                }}>
                        Selected Fields
                      </Typography>
                      <Tooltip title="Sort by name">
                        <IconButton onClick={() => {
                    const sortedFields = [...selectedFields].sort((a, b) => {
                      return sortSelectedFields ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                    });
                    setSelectedFields(sortedFields);
                    // Toggle the sorting order for the next click
                    setSortSelectedFields(!sortSelectedFields);
                  }}>
                          <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      // flip vertically if sorting in descending order
                      transform: `scaleY(${sortSelectedFields ? -1 : 1})`,
                      height: '14px',
                      width: '14px'
                    }}>
                            <UpwardArrowIcon />
                          </Box>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>

                  <Stack id="all-fields-container" direction="row" sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              pb: '20px'
            }}>
                    <Box id="available-fields-container" sx={{
                borderTop: '1px solid #fff',
                border: '1px solid #E2E2E2',
                borderRadius: '16px',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
                height: '445px',
                overflowY: 'scroll'
              }}>
                      <Box>
                        <Table id="table-available-fields" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '240px',
                    height: '100%'
                  }}>
                          <TableBody>
                            {availableFields.map(field => <TableRow sx={{
                        '& td, & th': {
                          borderBottom: 'none'
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        },
                        '&:active': {
                          backgroundColor: 'rgba(0, 0, 0, 0.08)'
                        },
                        cursor: 'pointer'
                      }} key={field.id} onClick={e => {
                        // if already in selectedFields return
                        if (selectedFields.includes(field)) {
                          return;
                        }
                        setSelectedFields([...selectedFields, field]);
                        // remove from availableFields
                        setAvailableFields(availableFields.filter(f => f.id !== field.id));
                      }}>
                                <TableCell sx={{
                          p: '4px',
                          '&:cursor': {
                            cursor: 'pointer'
                          }
                        }}>
                                  <Stack direction="row">
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              pl: '10px',
                              pr: '5px'
                            }}>
                                      {
                              // if the field is from customer, show customer icon
                              companyFields.find(field => field?.id === 'customer').fields.find(cf => cf.id === field.id) ? <CustomerIcon /> :
                              // show the icon based on the segmentData.included
                              segmentData?.included === 'possession' ? <VehicleIcon /> : 'transaction' ? <TransactionIcon /> : 'schedule' ? <Calendar30DaysIcon /> : 'cart' ? <ShoppingCartIcon /> : <CustomerIcon />}
                                    </Box>
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              p: '8px',
                              '&:cursor': {
                                cursor: 'pointer'
                              }
                            }}>
                                      {makeOverflowTooltip(field.name, 22)}
                                    </Box>
                                  </Stack>
                                </TableCell>
                              </TableRow>)}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                    <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: '15px'
              }}>
                      <ArrowCooldownIcon />
                    </Box>

                    <Box id="selected-fields-container" sx={{
                borderTop: '1px solid #fff',
                border: '1px solid #E2E2E2',
                borderRadius: '16px',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
                height: '445px',
                overflowY: 'scroll'
              }}>
                      <Box>
                        <Table id="table-selected-fields" sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '240px',
                    height: '100%'
                  }}>
                          <TableBody>
                            {selectedFields.map(field => <TableRow sx={{
                        '& td, & th': {
                          borderBottom: 'none'
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        },
                        '&:active': {
                          backgroundColor: 'rgba(0, 0, 0, 0.08)'
                        },
                        cursor: 'pointer'
                      }} key={field.id} onClick={e => {
                        setSelectedFields(selectedFields.filter(f => f.id !== field.id));
                        // add it back to availableFields
                        setAvailableFields([...availableFields, field]);
                      }}>
                                <TableCell sx={{
                          p: '4px',
                          cursor: 'pointer',
                          width: '300px'
                        }}>
                                  <Stack direction="row">
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              pl: '10px',
                              pr: '5px'
                            }}>
                                      {
                              // if the field is from customer, show customer icon
                              companyFields.find(field => field?.id === 'customer').fields.find(cf => cf.id === field.id) ? <CustomerIcon /> :
                              // show the icon based on the segmentData.included
                              segmentData?.included === 'possession' ? <VehicleIcon /> : 'transaction' ? <TransactionIcon /> : 'schedule' ? <Calendar30DaysIcon /> : 'cart' ? <ShoppingCartIcon /> : <CustomerIcon />}
                                    </Box>
                                    <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              p: '8px'
                            }}>
                                      {makeOverflowTooltip(field.name, 22)}
                                    </Box>
                                  </Stack>
                                </TableCell>
                              </TableRow>)}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                  </Stack>

                  <Box sx={{
              p: 0,
              marginLeft: '-24px',
              marginRight: '-24px'
            }}>
                    <Divider />
                  </Box>

                  <Stack sx={{
              pt: '25px'
            }}>
                    <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}>
                      <Button variant="text" sx={{
                  textTransform: 'none',
                  borderRadius: '20px',
                  minWidth: '100px'
                }} onClick={() => {
                  props.handleClose();
                  setExportModalOpen(false);
                }}>
                        Cancel
                      </Button>

                      <Button disabled={selectedFields.length === 0} variant="text" sx={{
                  textTransform: 'none',
                  borderRadius: '20px',
                  minWidth: '100px'
                }} onClick={() => {
                  handleExport();
                  props.handleClose();
                  setExportModalOpen(false);
                }}>
                        Export
                      </Button>
                    </Box>
                  </Stack>
                </>}
            </Box>
          </Modal>
        </>}
    </>;
};